<template>
  <div>
    <BuyerInvitedSuppliers v-if="company.buyer" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import BuyerInvitedSuppliers from './Buyer/BuyerInvitedSuppliers';

export default {
  name: 'CompanyInviteSuppliers',
  components: {
    BuyerInvitedSuppliers,
  },
  computed: {
    ...mapState('companies', ['company']),
  },
};
</script>
