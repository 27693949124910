<template>
  <div class="buyer-invited-suppliers-container">
    <b-loading is-full-page :active="loading.general" can-cancel></b-loading>

    <b-notification class="company-invitations-notification" type="is-info">
      <p>
        Você pode editar a lista de fornecedores em seu editor de texto ou planilhas preferido.
      </p>

      <div class="tips-container is-flex mt-5">
        <div class="box mr-3 custom-box">
          <span>A inserção do cabeçalho é obrigatória.</span>
        </div>
        <div class="box mr-3 custom-box">
          <span>As colunas devem estar na mesma ordem dos exemplos.</span>
        </div>
        <div class="box mr-3 custom-box">
          <span>Os separadores de coluna podem ser vírgula ou ponto e vírgula.</span>
        </div>
        <div class="box custom-box"><span>Linhas com mais colunas serão filtradas.</span></div>
      </div>

      <div class="box is-flex example-tables-container">
        <div class="example-table">
          <p><b>CNPJ,E-mail,Denominação Social</b></p>

          <table class="example-table">
            <tr>
              <td>11.111.111/1111-11,</td>
              <td>fornecedor1@email.com,</td>
              <td>Fornecedor 1</td>
            </tr>

            <tr>
              <td>22.222.222/2222-22,</td>
              <td>fornecedor2@email.com,</td>
              <td>Fornecedor 2</td>
            </tr>
          </table>
        </div>

        <table class="example-border-table">
          <tr>
            <th>CNPJ</th>
            <th>E-mail</th>
            <th>Denominação Social</th>
          </tr>

          <tr>
            <td>11.111.111/1111-11</td>
            <td>fornecedor1@email.com</td>
            <td>Fornecedor 1</td>
          </tr>

          <tr>
            <td>22.222.222/2222-22</td>
            <td>fornecedor2@email.com</td>
            <td>Fornecedor 2</td>
          </tr>
        </table>
      </div>

      <p>
        Após concluir a edição, salve ou exporte com o formato do arquivo na extensão <b>.csv</b>.
      </p>
    </b-notification>

    <form>
      <div class="button-container">
        <b-field grouped class="column-direction-touch">
          <b-field class="invite-document-upload-field">
            <div class="field">
              <b-upload
                v-model="data.file"
                :disabled="disableFields"
                @input="
                  {
                    resetCompanyInvitedSuppliersUpload(), saveDocument();
                  }
                "
              >
                <a
                  class="button is-primary is-fullwidth"
                  :class="{ 'is-loading': loading.save }"
                  :disabled="disableFields"
                >
                  <b-icon icon="upload"></b-icon>
                  <span>Carregar Arquivo</span>
                </a>
              </b-upload>
            </div>
          </b-field>
        </b-field>
      </div>
    </form>

    <div>
      <b-progress
        show-value
        class="invite-suppliers-progress-upload"
        format="percent"
        type="is-info"
        size="is-medium"
        :value="invitedSuppliersUpload"
      ></b-progress>
    </div>

    <hr />

    <b-table
      paginated
      per-page="8"
      class="table is-fullwidth"
      v-if="invitedSuppliers.length"
      :data="invitedSuppliers"
    >
      <b-table-column sortable field="name" label="Documento" v-slot="props">
        <a download v-if="!disableFields" :href="fetchCompanyInvitation(props.row.filename)">{{
          props.row.file
        }}</a>
        <p v-else>{{ props.row.filename }}</p>
      </b-table-column>

      <b-table-column field="status" label="Status" v-slot="props" width="100">
        <b-tag :type="getStatusTagType(props.row.status)">{{
          getStatusName(props.row.status)
        }}</b-tag>
      </b-table-column>

      <b-table-column
        centered
        sortable
        field="createdAt"
        label="Adicionado em"
        width="175"
        v-slot="props"
      >
        {{ props.row.createdAt | formatDateTime }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { fileServer } from '@/utils/functions';
import { formatarDataSegundo as formatDateTime } from '@/utils/format';

export default {
  name: 'BuyerInvitedSuppliers',
  data() {
    const data = {
      file: [],
      fileName: null,
    };

    const loading = {
      general: false,
      save: false,
    };

    return {
      data,
      loading,
      uploadLimit: 5242880,
    };
  },
  filters: {
    formatDateTime,
  },
  computed: {
    ...mapState('companies', ['company']),
    ...mapState('companiesInvitedSuppliers', ['invitedSuppliers', 'invitedSuppliersUpload']),
    ...mapGetters({
      lastValidation: 'lastValidation',
      logged: 'getLogged',
      user: 'getUser',
    }),
    disableFields() {
      return (
        !this.logged || this.lastValidation.status === 'PENDENTE' || !this.editCompanyPermission
      );
    },
    editCompanyPermission() {
      return this.user.empresas.some(
        companyUser => companyUser.id === this.company.id && companyUser.editar_empresa,
      );
    },
  },
  methods: {
    ...mapActions('companiesInvitedSuppliers', [
      'addCompanyInvitedSuppliers',
      'fetchCompanyInvitedSuppliers',
      'resetCompanyInvitedSuppliersUpload',
    ]),
    fetchCompanyInvitation(filename) {
      return fileServer(filename, 'invitations');
    },
    getStatusName(status) {
      return {
        ENVIADO: 'ENVIADO',
        ERRO: 'ERRO',
        PROCESSAMENTO: 'EM PROCESSAMENTO',
      }[status];
    },
    getStatusTagType(status) {
      return {
        ENVIADO: 'is-success',
        ERRO: 'is-danger',
        PROCESSAMENTO: 'is-info',
      }[status];
    },
    async saveDocument() {
      if (!['text/csv', 'text/plain', 'application/vnd.ms-excel'].includes(this.data.file.type)) {
        this.$alerta('Insira um arquivo com o formato .csv', 'is-danger');
        return;
      }

      if (this.data.file.size > this.uploadLimit) {
        this.$alerta('Insira um arquivo com até 5MB', 'is-danger');
        return;
      }

      const data = new FormData();
      data.append('invitation', this.data.file);

      this.loading.save = true;
      try {
        await this.addCompanyInvitedSuppliers({ companyId: this.company.id, data });

        this.$alerta('Convite(s) enviado(s)', 'is-success');
      } catch (error) {
        if (error?.response?.data?.message === 'ValidationError') {
          this.$snackbar(error.response.data.details.message, 'is-warning', 'is-top', true);
          return;
        }
        this.$alerta('Erro ao convidar fornecedores', 'is-danger');
      } finally {
        this.loading.save = false;
        this.data.file = [];
      }
    },
  },
  mounted() {
    this.resetCompanyInvitedSuppliersUpload();
  },
  async created() {
    this.loading.general = true;
    try {
      await this.fetchCompanyInvitedSuppliers(this.company.id);
    } catch (error) {
      this.$alerta('Erro ao buscar dados', 'is-danger');
    } finally {
      this.loading.general = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.button-container {
  display: flex;
}

.custom-box {
  font-size: 0.9rem;
  max-height: 90px;
}

.example-border-table {
  border: 1px solid black;

  td,
  th {
    border: 1px solid black;
    color: black;
    padding: 0 10px;
  }
}

.example-tables-container {
  font-size: 0.9rem;
  justify-content: space-between;
}

.invite-suppliers-progress-upload {
  margin-top: 1.5rem;
}

@media (max-width: 1023px) {
  .invite-document-upload-field {
    margin-top: 0.75rem;

    .field .upload {
      width: 100%;
    }
  }

  .invite-suppliers-progress-upload {
    margin-top: 0.75rem;
  }

  .tips-container {
    flex-direction: column;
  }
}

@media (max-width: 1120px) {
  .example-table {
    margin-bottom: 10px;
  }

  .example-tables-container {
    flex-direction: column;
  }
}
</style>

<style lang="scss">
.company-invitations-notification {
  .media {
    align-items: center;
  }
}
</style>
